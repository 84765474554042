import { DOCUMENT } from '@angular/common';
import { inject, Injectable, type OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ActivatedRouteSnapshot,
  ActivationEnd,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { LOCATION } from '@ng-web-apis/common';
import { BehaviorSubject } from 'rxjs';

import { injectAppDestroyRef } from '@cosmos/util-app-destroy-ref';

@Injectable({ providedIn: 'root' })
export class RouteSnapshotService implements OnDestroy {
  private readonly _router = inject(Router);
  private readonly _document = inject(DOCUMENT);
  private readonly _location = inject(LOCATION);
  readonly previousSnapshot$ =
    new BehaviorSubject<ActivatedRouteSnapshot | null>(null);
  readonly snapshot$ = new BehaviorSubject<ActivatedRouteSnapshot | null>(null);
  readonly previousReferrerUrl$ = new BehaviorSubject<string | null>(null);

  private _currentUrl = '';

  private readonly _appDestroyRef = injectAppDestroyRef();

  constructor() {
    let lastRouteSnapshot: ActivatedRouteSnapshot | null = null;

    this._router.events
      .pipe(takeUntilDestroyed(this._appDestroyRef))
      .subscribe({
        next: (event) => {
          if (event instanceof NavigationStart) {
            lastRouteSnapshot = null;
          } else if (event instanceof ActivationEnd && !lastRouteSnapshot) {
            lastRouteSnapshot = event.snapshot;
          } else if (event instanceof NavigationEnd && lastRouteSnapshot) {
            this.previousSnapshot$.next(this.snapshot$.value);
            this.previousReferrerUrl$.next(this._currentUrl);
            this._currentUrl = global_isBrowser
              ? this._document.location.href
              : this._location.href;
            this.snapshot$.next(lastRouteSnapshot);
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.previousSnapshot$.complete();
    this.snapshot$.complete();
    this.previousReferrerUrl$.complete();
  }
}
